.layout {
  position: relative;
  display: flex;
  font-size: 0.9em;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: Outfit, sans-serif;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: 991px) {
    align-items: stretch;
  }
  background-image: radial-gradient(
      circle farthest-corner at 50% 50%,
      rgba(126, 204, 255, 0.2),
      rgba(252, 252, 252, 0)
    ),
    url("./imgs/bg.png");
  background-size: cover;
  & > div {
    width: 85%;
  }
}
.container {
  display: block;
  width: auto;
  min-height: 668px;
  max-width: 1307px;
  position: relative;
  font-family: "Outfit-medium";
  margin: 2em auto;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.75)
  );
  border: 1.5px solid #009ff7;
  border-radius: 5px;
  box-shadow: 0px 18px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  padding-bottom: 60px;
  text-align: center;
  &.login {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 70%;
      text-align: center;
    }
  }
}
.quiz {
  font-family: "Outfit-SemiBold";
  text-transform: capitalize;
  margin: 0 0 30px;
  text-align: center;
  animation: fadeIn ease-out 1s forwards;
  font-size: 50px;
  line-height: 1.2em;
  min-height: 90px;
  @media (max-width: 768px) {
    font-size: 30px;
    min-height: 40px;
  }
  span {
    background-image: linear-gradient(180deg, #54e3fb, #0361c8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}
.int {
  width: 70%;
  max-width: 800px;
  padding-left: 1em;
  height: 3.5em;
  border-radius: 3px;
  border: 1px solid #ddd;
  background: #f3f3f3;
  font-size: 1.1em;
  font-family: "Outfit-medium";
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  &:focus,
  &:active {
    outline: none;
  }
  margin-bottom: 10px;
}
.datepicker {
  border-radius: 3px;
  border: 1px solid #ddd;
  background: #f3f3f3;
  font-size: 15px;
  font-family: "Outfit-medium";
  box-sizing: border-box;
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  &:focus,
  &:active {
    outline: none;
  }
  margin-bottom: 10px;
}
.summaryTxt {
  margin: 10px auto;
  font-weight: 600;
  font-size: 16px;
}
.labelTxt {
  width: 70%;
  max-width: 800px;
  text-align: left;
  margin: 10px auto;
  padding-left: 10px;
  font-weight: 600;
  font-size: 15px;
}
.fc-container {
  display: block;
  width: auto;
  max-width: 600px;
  position: relative;
  font-family: "Outfit-medium";
  padding: 20px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.75)
  );
  border: 1.5px solid #009ff7;
  border-radius: 5px;
  box-shadow: 0px 18px 15px rgba(0, 0, 0, 0.2);
  padding: 10px;
  &.login {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 70%;
    }
  }
}
.login-zone {
  border-radius: 20px;
  min-height: 400px;
  text-align: center;
  .content {
    text-align: center;
    font-size: 15px;
    border-radius: 20px;
    padding: 107px 37px 37px;
    margin-bottom: 20px;
  }
  .err {
    color: #ec7211;
  }
}

.chat-zone {
  .cancel {
    height: 50px;
    text-align: right;
  }
  .chat-content {
    width: 100%;
    height: 600px;
    overflow: auto;
    padding: 10px 0;
    .chat-item {
      margin-bottom: 10px;
      display: flex;
      &.ymsg {
        justify-content: flex-end;
        .mentioned {
          color: #000;
          background: #fff;
        }
      }
      &.cmsg {
        justify-content: flex-start;
        .mentioned {
          color: #fff;
          background: #08192b;
        }
      }
      .mentioned {
        width: 80%;
        max-width: 300px;
        border: 1px solid #ced4da;
        border-radius: 0.5rem;
        padding: 15px;
        font-size: 17px;
      }
    }
  }
  img {
    cursor: pointer;
  }
  .chat-input {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    input {
      width: calc(100% - 80px);
      font-size: 16px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      padding: 13px 10px;
      display: block;
      &:focus,
      &:active {
        outline: none;
      }
    }
    img {
      margin-left: 10px;
      width: 34px;
      display: block;
      cursor: pointer;
    }
  }
}

.login-zone {
  a {
    text-decoration: none;
  }
  .fc-btn {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #fff;
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #3b55a0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354c8c;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 34px;
      height: 100%;
      border-right: #364e92 1px solid;
      background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
        6px 6px no-repeat;
    }
    &:hover,
    &:focus {
      background-color: #5b7bd5;
      background-image: linear-gradient(#5b7bd5, #4864b1);
    }
  }

  .gl-btn {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #fff;
    background: #dd4b39;
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354c8c;
    width: 228px;
    height: 38px;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 2px;
      left: 0;
      width: 34px;
      height: calc(100% - 5px);
      border-right: #bb3f30 1px solid;
      background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
        6px 6px no-repeat;
    }
    &:hover,
    &:focus {
      background: #e74b37;
    }
  }

  .lk-btn {
    display: block;
    border: 0px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 16px;
    margin: 5px auto;
    overflow: hidden;
    padding: 0px 10px;
    user-select: none;
    height: 38px;
    background: rgb(26, 129, 185);
    width: 228px;
  }
}
.btn {
  text-transform: capitalize;
  animation: fadeIn ease-out 1.5s forwards;
  width: 100%;
  margin: 30px 5px 20px;
  padding: 0.5em 0 0.5em 0;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  color: white;
  font-size: 20px;
  letter-spacing: 0.01em;
  text-shadow: 0px 3.39148px 3.39148px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(180deg, #fd594d 0%, #c10d00 100%);
  box-shadow: inset 2px 3px 3px 0 rgba(255, 255, 255, 0.24);
  border: 0;
  width: 150px;
}
.next-link {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid;
  margin-right: 20px;
  color: #2a52f5;
  cursor: pointer;
  position: relative;
  &:hover {
    color: #0233f7;
  }
  &.back:before {
    content: "<";
    position: absolute;
    left: -15px;
    top: 2px;
  }
  &.next:before {
    content: ">";
    position: absolute;
    right: -15px;
    top: 2px;
  }
}
.loading {
  width: 100% !important;
  height: 100%;
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.subtitle {
  max-width: 674px;
  margin: 20px auto;
  font-size: 23px;
  text-align: left;
  animation: fadeIn ease-out 1s forwards;
  color: #252525;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 13px;
  }
}

.terms {
  max-width: 674px;
  margin: 10px auto;
  font-size: 17px;
  text-align: left;
  animation: fadeIn ease-out 1s forwards;
  color: #252525;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.Toastify__toast {
  color: #fff !important;
  font-weight: 600;
  svg {
    fill: #fff !important;
  }
  &--success {
    background: rgba(#65dec1, 0.9) !important;
  }
  &--warning {
    background: rgba(#fc6a34, 0.9) !important;
  }
}
.Toastify__close-button--light {
  opacity: 1 !important;
  color: #fff !important;
}

select {
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background: transparent;
  background-size: 16px 12px;
  border: 1px solid #000;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  font-family: "Outfit-medium";
}
